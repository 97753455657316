import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./App.scss";
import { serviceAreaList } from "./data/tempData";
import localAxios from "./api/index";
import axios from "axios";
import Marquee from "react-fast-marquee";

const marqueeText =
  "개성 있는 커피 브랜드로 카페 창업을 준비하는 창업자, 나의 건물에 직접 카페를 운영할 건물주, 나의 카페 디벨럽을 위해 개성있는 커피 브랜드로 카페를 열고 싶은 예비 창업자, 시작하는 사람을 위한 커피 스타트 가이드 ‘스티치프렌즈 커피 컴퍼니’ -";

const App = () => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [coffeeStoreList, setCoffeeStoreList] = useState([]);
  const [modalMsg, setModalMsg] = useState("");
  const [coffeeStoreIdx, setCoffeeStoreIdx] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    interested_brand: "",
    inquiry_type: "",
    content: "",
  });

  const [formFile, setFormFile] = useState();
  const [isAgree, setIsAgree] = useState(false);

  const handleFormData = e => {
    let updatedFormData = formData;
    updatedFormData[e.target.name] = e.target.value;
    setFormData(updatedFormData);
  };

  const handleFormFile = e => {
    if (!e.target.files.length) return;

    const maxAllowedSize = 5 * 1024 * 1024;
    if (e.target.files[0].size > maxAllowedSize)
      return setModalMsg("파일 사이즈는 5mb를 초과할 수 없습니다");
    setFormFile(e.target.files[0]);
  };

  const handleFormSubmit = () => {
    if (!isAgree) return setModalMsg("개인보호 정책에 동의해주세요");

    let bodyFormData = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      bodyFormData.append(key, value);
    }
    if (formFile) bodyFormData.append("file", formFile);

    axios({
      url: process.env.REACT_APP_API_URL + "/coffee/inquiry/",
      method: "POST",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        setModalMsg("문의가 접수되었습니다.");
      })
      .catch(error => {
        setModalMsg("Please fill your " + Object.keys(error.response.data)[0]);
      });
  };

  useEffect(() => {
    const fetchCoffeeStoreList = async () => {
      const { data } = await localAxios.get("/coffee/stores/");
      setCoffeeStoreList(data.results);
    };
    fetchCoffeeStoreList();
  }, []);

  return (
    <Container className="App gx-0 fit-width" fluid>
      <Modal
        className="modal-alert"
        show={modalMsg.length}
        size="md"
        onHide={() => setModalMsg("")}
      >
        <Modal.Body>{modalMsg}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalMsg("")}>닫기</Button>
        </Modal.Footer>
      </Modal>

      {coffeeStoreIdx != null ? (
        <Modal
          show={isDetailModalOpen && coffeeStoreIdx != null}
          size="xl"
          className="modal-store-detail"
          onHide={() => setIsDetailModalOpen(false)}
        >
          <Modal.Header className="p-0">
            <Modal.Title className="font-grotesk">
              Stitch Friends Coffee Company
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => setIsDetailModalOpen(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="pb-5">
              <Col md={6}>
                <h1 className="fw-bold font-roboto">
                  {coffeeStoreList[coffeeStoreIdx].name_english}
                </h1>
              </Col>
              <Col className="mb-3 fw-bold" md={6}>
                {coffeeStoreList[coffeeStoreIdx].desc}
              </Col>
            </Row>
            <Row>
              <Col md={2} className="font-roboto">
                BRAND
              </Col>
              <Col md={4}>{coffeeStoreList[coffeeStoreIdx].name}</Col>
              <Col md={2} className="font-roboto second-line">
                SNS
              </Col>
              <Col md={4}>{coffeeStoreList[coffeeStoreIdx].sns}</Col>
            </Row>
            <Row>
              <Col md={2} className="font-roboto">
                BRANCH
              </Col>
              <Col md={4}>{coffeeStoreList[coffeeStoreIdx].branch}</Col>
              <Col md={2} className="font-roboto second-line">
                WEBSITE
              </Col>
              <Col md={4}>
                <a
                  href={coffeeStoreList[coffeeStoreIdx].website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {coffeeStoreList[coffeeStoreIdx].website}
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={2} className="font-roboto">
                ESTABLISH
              </Col>
              <Col md={4}>{coffeeStoreList[coffeeStoreIdx].establish}</Col>
              <Col md={2} className="font-roboto second-line">
                SIGNATURE
              </Col>
              <Col md={4}>{coffeeStoreList[coffeeStoreIdx].signature}</Col>
            </Row>
            <Row>
              <Col md={2} className="font-roboto">
                SPECIALITY
              </Col>
              <Col md={4}>{coffeeStoreList[coffeeStoreIdx].specialty}</Col>
            </Row>
            <Row className="store-feed">
              {coffeeStoreList[coffeeStoreIdx].images.map((img, idx) => (
                <Col
                  xs={12}
                  sm={4}
                  className="mb-4"
                  key={`store-detail-img-${idx}`}
                >
                  <img src={img.image} alt="thumbnail" />
                </Col>
              ))}
            </Row>
          </Modal.Body>
        </Modal>
      ) : null}

      <Marquee
        direction="left"
        className="text-yellow fw-bold mt-3 marquee-text"
      >
        {marqueeText}
      </Marquee>

      <Container className="homepage gx-0 fit-width" fluid>
        <Col className="section-heading gx-0 d-flex mt-5">
          <Col className="heading-text">
            <img src="/images/heading-text.svg" alt="heading text image" />
          </Col>
          <Col xs={1} className="heading-object heading-object-1 float1">
            <img src="/images/heading-object-1.png" alt="heading obj" />
          </Col>
          <Col xs={1} className="heading-object heading-object-2 float2">
            <img src="/images/heading-object-2.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-3 float3">
            <img src="/images/heading-object-3.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-4 float1">
            <img src="/images/heading-object-4.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-5 float2">
            <img src="/images/heading-object-5.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-6 float3">
            <img src="/images/heading-object-6.png" alt="heading obj" />
          </Col>
          <Col xs={3} className="heading-object heading-object-7 float1">
            <img src="/images/heading-object-7.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-8 float2">
            <img src="/images/heading-object-8.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-9 float3">
            <img src="/images/heading-object-9.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-10 float1">
            <img src="/images/heading-object-10.png" alt="heading obj" />
          </Col>
          <Col xs={2} className="heading-object heading-object-11 float2">
            <img src="/images/heading-object-11.png" alt="heading obj" />
          </Col>
        </Col>

        <Col className="section-stores">
          <Container className="my-5">
            <p className="copy-message text-yellow text-center mb-5">
              <strong>개성있는 커피 브랜드</strong>들이 카페를 만들고 싶은
              분들의 <strong>창업을 함께</strong> 합니다.
            </p>
            <Col className="sticker">
              <img src="/images/sticker.svg" alt="sticker" />
            </Col>
            <Row className="store-list">
              {coffeeStoreList.map((x, i) => (
                <Col
                  xs={6}
                  md={4}
                  lg={3}
                  key={x.name_english}
                  className="mb-4 store-item"
                >
                  <Col
                    className="w-100 store-item-inner"
                    onClick={() => {
                      setCoffeeStoreIdx(i);
                      setIsDetailModalOpen(true);
                    }}
                  >
                    <img src={x.thumbnail} alt="store item" />
                    <Col className="store-item-hover d-flex flex-column text-red">
                      <Col className="mt-2 d-flex align-items-baseline justify-content-between">
                        <h3 className="m-0">{x.name_english}</h3>
                        <img src="images/icon-plus.svg" alt="icon plus" />
                      </Col>
                      <h6 className="fw-bold">{x.name}</h6>
                      <Col className="mt-4">
                        {x.tags.map((tag, y) => (
                          <span
                            className="store-item-tag btn btn-red fw-bold text-black"
                            key={tag + i + y}
                          >
                            {tag.name}
                          </span>
                        ))}
                      </Col>
                    </Col>
                  </Col>
                </Col>
              ))}
            </Row>
          </Container>
        </Col>
        <Col className="section-service-area bg-yellow pb-5">
          <Container className="py-5">
            <h3 className="font-grotesk text-red mt-5">
              Stitch Friends Coffee Company
              <span className="fw-bold"> Service Area</span>
            </h3>

            <p className="copy-message font-grotesk text-red text-center fw-bold mb-5">
              스티치 프렌즈 커피컴퍼니 서비스 영역을 소개합니다.
            </p>
            <Row className="service-area-list text-center">
              {serviceAreaList.map((x, idx) => (
                <Col
                  key={x.title1 + idx}
                  lg={4}
                  className="service-area-item mb-4 cursor-pointer"
                  onClick={() =>
                    // eslint-disable-next-line no-undef
                    window.document
                      .getElementById("section-join")
                      .scrollIntoView()
                  }
                >
                  <Col
                    className="service-area-item-image"
                    style={{
                      backgroundImage: `url("${x.img}")`,
                    }}
                  ></Col>
                  <Col className="service-area-item-text py-4 px-2 bg-red text-yellow">
                    <Col className="item-title mt-1 mb-5">
                      {x.title1}
                      <br />
                      {x.title2}
                    </Col>
                    <Col className="item-desc">{x.desc}</Col>
                    <Col
                      className="item-title-en font-grotesk text-uppercase"
                      dangerouslySetInnerHTML={{ __html: x.titleEn }}
                    ></Col>
                  </Col>
                </Col>
              ))}
            </Row>
            <Row className="service-area-bottom text-red g-0">
              <Col
                className="service-bg"
                sm={8}
                style={{ backgroundImage: `url('/images/entry.jpg')` }}
              ></Col>
              <Col sm={4}>
                <Col className="p-5 service-area-inner bg-black">
                  <Col className="mb-1">커피 브랜드를 위한 공간</Col>
                  <h5 className="font-grotesk fw-bold mb-5">
                    SPACE FOR COFFEE BRANDS
                  </h5>
                  <p>
                    커피 프로그램이나 클래스를 할 수 있는 공간,
                    <br /> 카페를 운영하기 전 테스트를 해볼 수 있는 팝업공간,
                    <br />
                    카페를 운영할 입점 공간이 필요하신 하시다면
                    <br />
                    스티치 프렌즈 커피 컴퍼니에 문의하세요.
                  </p>
                  <a href="#section-join">
                    <img src="images/arrow-down.svg" alt="arrow down" />
                  </a>
                </Col>
              </Col>
            </Row>
          </Container>
        </Col>

        <Col id="section-join" className="section-join p-5 mb-5">
          <h1 className="text-white font-grotesk fw-bold pt-5 m-5">
            JOIN OUR COMMUNITY
          </h1>
          <Row className="justify-content-center d-flex">
            <Col className="d-flex flex-column" md={10} lg={6}>
              <Form>
                <Form.Control
                  type="text"
                  placeholder="*이름"
                  className="mb-3"
                  name="name"
                  onChange={handleFormData}
                />
                <Form.Control
                  type="email"
                  placeholder="*이메일 주소"
                  className="mb-3"
                  name="email"
                  onChange={handleFormData}
                />
                <Form.Control
                  type="text"
                  placeholder="*연락처"
                  className="mb-3"
                  name="phone"
                  onChange={handleFormData}
                />
                <Form.Select
                  className="form-select mb-3"
                  name="interested_brand"
                  onChange={handleFormData}
                >
                  <option>* 관심있는 패밀리 브랜드를 알려주세요</option>
                  {coffeeStoreList.map(x => (
                    <option key={"option" + x.name} value={x.name}>
                      {x.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  name="inquiry_type"
                  onChange={handleFormData}
                  className="form-select mb-3"
                >
                  {[
                    "* 문의하실 비즈니스 유형을 선택해주세요",
                    "카페 창업을 위해 커피브랜드와 공간을 찾고 있어요.",
                    "나의 건물에 직접 카페를 운영하고 싶어요.",
                    "내가 운영하는 카페의 디벨럽을 위해 컨설팅을 받고 싶어요.",
                    "원두를 공급받고 싶어요",
                    "기타",
                  ].map(x => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </Form.Select>
                <Form.Group className="d-flex mb-3 form-group-attachment">
                  <Form.Control
                    type="file"
                    id="form-attachment"
                    className="hidden"
                    onChange={handleFormFile}
                  />
                  <Form.Label
                    htmlFor="form-attachment"
                    className="w-100 d-flex justify-content-between form-label cursor-pointer"
                  >
                    <span>
                      {formFile ? (
                        <>{formFile.name}</>
                      ) : (
                        "소개서 등 참고할 만한 자료를 업로드해주세요. (최대 5MB)"
                      )}
                    </span>
                    <img src="/images/icon-upload.svg" alt="upload icon" />
                  </Form.Label>
                </Form.Group>
                <Form.Control
                  type="text"
                  placeholder="sns나 웹사이트 등 참고할 만한 온라인 주소를 알려주세요"
                  className="mb-3"
                  name="website"
                  onChange={handleFormData}
                />
                <Form.Control
                  as="textarea"
                  rows={10}
                  maxLength={1024}
                  placeholder="*궁금한 사항이나 상세한 설명을 남겨주세요"
                  className="mb-3"
                  name="content"
                  onChange={handleFormData}
                />
                <Form.Group className="d-flex">
                  <Form.Check
                    id="agreement"
                    type="checkbox"
                    defaultChecked={isAgree}
                    onClick={() => setIsAgree(!isAgree)}
                  />
                  <Form.Label
                    htmlFor="agreement"
                    className="agreement-label cursor-pointer"
                  >
                    개인보호 정책에 동의합니다.
                  </Form.Label>
                </Form.Group>

                <Button className="text-red mt-5" onClick={handleFormSubmit}>
                  문의하기
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Container>

      <Container className="footer my-5 text-white">
        Copyright © 2024 LocalStitch. All rights reserved.
      </Container>
    </Container>
  );
};

export default App;
