export const storeList = [
  {
    img: "/temp/store-1.jpg",
    id: "1",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
  {
    img: "/temp/store-2.jpg",
    id: "2",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
  {
    img: "/temp/store-3.jpg",
    id: "3",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
  {
    img: "/temp/store-4.jpg",
    id: "4",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
  {
    img: "/temp/store-5.jpg",
    id: "5",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
  {
    img: "/temp/store-6.jpg",
    id: "6",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
  {
    img: "/temp/store-7.jpg",
    id: "7",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
  {
    img: "/temp/store-8.jpg",
    id: "8",
    title: "스티치 커피",
    desc: "스티치 커피 설명입니다.",
  },
];

export const serviceAreaList = [
  {
    img: "/images/service-area-1.svg",
    title1: "카페 창업을",
    title2: "준비하고 계신 분",
    desc: "창업을 위한 커피 브랜드와 공간이 준비되어 있습니다.",
    titleEn: "a <strong>cafe start-up<strong>",
  },
  {
    img: "/images/service-area-2.svg",
    title1: "내가 가진 건물에",
    title2: "카페를 운영하고 싶은 분",
    desc: "차별화된 커피, 개성있는 카페  브랜드들이 있습니다.",
    titleEn: "The <strong>owner</strong> of the <strong>building</strong>",
  },
  {
    img: "/images/service-area-3.svg",
    title1: "커피 신규 브랜드를",
    title2: "만들고 싶은 기업이나 개인",
    desc: "원두, 메뉴 개발부터 공간리뉴얼, 운영 전략까지 커피브랜드 전문가들이 있습니다.",
    titleEn: "The <strong>owner</strong> of the <strong>cafe</strong>",
  },
];
