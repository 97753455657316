import axios from "axios";

const localAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: status => {
    return status < 500;
  },
});

export default localAxios;
